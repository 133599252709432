import { Database } from "@/lib/database.types"
import { useEffect, useState } from "react"
import { SessionContextProvider } from "@supabase/auth-helpers-react"
import { createClient, Session } from "@supabase/supabase-js"
import { Loader2 } from "lucide-react"
import { RouterProvider } from "react-router-dom"
import { Toaster as Sonner } from "sonner"
import { useRouters } from "./routers"
import { useAppStore } from "./store"

const supabaseClient = createClient<Database>(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_ANON_KEY)

function App() {
  const { setState } = useAppStore

  const [session, setSession] = useState<Session | null>()
  const [isLoading, setIsLoading] = useState(true)

  const onAuthStateChange = (callback: (session: Session | null) => void) => {
    let currentSession: Session | null
    return supabaseClient.auth.onAuthStateChange((_event, session) => {
      if (session?.user?.id == currentSession?.user?.id) return
      currentSession = session
      callback(session)
    })
  }

  useEffect(() => {
    supabaseClient.auth
      .getSession()
      .then(async ({ data: { session } }) => {
        setSession(session)

        const { data: userPermissions } = await supabaseClient.rpc("permissions_list")

        setState({
          isLeadership: userPermissions?.includes("isLeadership"),
          canSubmitAfterDeadline: userPermissions?.includes("canSubmitAfterDeadline"),
        })
      })
      .finally(() => setIsLoading(false))

    const {
      data: { subscription },
    } = onAuthStateChange(async (session) => {
      setIsLoading(true)

      supabaseClient.rpc("permissions_list").then(({ data: userPermissions }) => {
        setState({
          isLeadership: userPermissions?.includes("isLeadership"),
          canSubmitAfterDeadline: userPermissions?.includes("canSubmitAfterDeadline"),
        })
        setSession(session)

        setIsLoading(false)
      })
    })

    return () => subscription.unsubscribe()
  }, [])

  if (isLoading)
    return (
      <div className="h-screen grid place-content-center">
        <Loader2 className="animate-spin w-10 h-10" />
      </div>
    )

  return (
    <SessionContextProvider supabaseClient={supabaseClient} initialSession={session}>
      <WithSession />
      <Sonner richColors />
    </SessionContextProvider>
  )
}

function WithSession() {
  const router = useRouters()
  return <RouterProvider router={router} />
}

export default App
