import { RequirePermission } from "@/src/hoc/RouteDecorators.tsx"
import ApplyFormPageLayout from "@/src/layouts/ApplyFormPageLayout.tsx"
import { createBrowserRouter, redirect, RouteObject } from "react-router-dom"
import ApplicationSubmittedPage from "./pages/ApplicationSubmitted"
import ForgotPassword from "./pages/ForgotPassword"
import Login from "./pages/Login"
import RegisterPage from "./pages/Register"
// import ResetPassword from "./pages/ResetPassword";
import ResetPassword from "./pages/ResetPassword"
import DetailsPage from "./pages/apply/Details"
import AgreementsPage from "./pages/apply/Agreements"
import AvailabilityPage from "./pages/apply/Availability"
import SummaryPage from "./pages/apply/Summary"
import ContactPage from "./pages/apply/Contact"
import PhotoPage from "./pages/apply/Photo"
import ParticipationPage from "./pages/apply/Participation"
import ApplicantsPage from "./pages/apply/Applicants"
import ArrivePhotosPage from "./pages/arrive/ArrivePhotos"
import CastingList from "./pages/casting/CastingList"
import CoreCast from "./pages/casting/CoreCast"
import FamilyCast from "./pages/casting/FamilyCast"
import CreateOrderMemorabiliaPage from "./pages/manage/CreateOrderMemorabilia"
import ManageOrderMemorabiliaPage from "./pages/manage/ManageOrderMemorabilia"
import UpdateOrderMemorabiliaPage from "./pages/manage/UpdateOrderMemorabilia"
import OrderMealsPage from "./pages/order/OrderMeals"
import OrderMemorabiliaPage from "./pages/order/OrderMemorabilia"
import TemplateEditor from "@/src/pages/TemplateEditor.tsx"
import * as Sentry from "@sentry/react"
import InstrumentsPage from "@/src/pages/apply/Instruments.tsx"
import { useSession } from "@supabase/auth-helpers-react"
import AdminCommentsPage from "./pages/apply/AdminComments"
// import OrderMeals2 from "@/src/pages/order/OrderMeals2.tsx"

const loggedOutPages: RouteObject[] = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/apply/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
]

const loggedInPages: RouteObject[] = [
  {
    element: <ApplyFormPageLayout showTabs={false} />,
    children: [
      {
        index: true,
        element: <ApplicationSubmittedPage />,
      },
    ],
  },
  {
    element: <RequirePermission anyOf={["isLeadership", "isSupervisor", "canSubmitAfterDeadline"]} />,
    children: [
      {
        element: <ApplyFormPageLayout />,
        path: `/apply/:application_id`,
        children: [
          {
            index: true,
            path: "applicants",
            element: <ApplicantsPage />,
          },
          {
            path: "participation",
            element: <ParticipationPage />,
          },
          {
            path: "contact",
            element: <ContactPage />,
          },
          {
            path: "photo",
            element: <PhotoPage />,
          },
          {
            path: "availability",
            element: <AvailabilityPage />,
          },
          {
            path: "details",
            element: <DetailsPage />,
          },
          {
            path: "instruments",
            element: <InstrumentsPage />,
          },
          {
            path: "agreements",
            element: <AgreementsPage />,
          },
          {
            path: "summary",
            element: <SummaryPage />,
          },
          {
            path: "admin-notes",
            element: <RequirePermission anyOf={["isLeadership", "isSupervisor"]} />,
            children: [
              {
                index: true,
                element: <AdminCommentsPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/arrive/:id",
    children: [
      {
        index: true,
        path: "photos",
        element: <ArrivePhotosPage />,
      },
    ],
  },
  {
    path: "/order/:id",
    children: [
      {
        index: true,
        path: "memorabilia",
        element: <OrderMemorabiliaPage />,
      },
      {
        path: "meals",
        element: <OrderMealsPage />,
      },
      // {
      //   path: "meals2",
      //   element: <OrderMeals2 />,
      // },
    ],
  },
  {
    path: "/casting",
    element: <RequirePermission anyOf={["isLeadership", "isSupervisor"]} />,
    children: [
      {
        index: true,
        path: "list",
        element: <CastingList />,
      },
      {
        path: "family",
        element: <FamilyCast />,
      },
      {
        path: "core",
        element: <CoreCast />,
      },
    ],
  },
  {
    path: "/arrive/:id",
    children: [
      {
        index: true,
        path: "photos",
        element: <ArrivePhotosPage />,
      },
    ],
  },
  {
    path: "/manage",
    element: <RequirePermission anyOf={["isLeadership", "usesMemorabiliaOrders"]} />,
    children: [
      {
        path: "memorabilia",
        children: [
          {
            path: "",
            element: <ManageOrderMemorabiliaPage />,
          },
          {
            path: ":item_id",
            element: <UpdateOrderMemorabiliaPage />,
          },
          {
            path: "new",
            element: <CreateOrderMemorabiliaPage />,
          },
        ],
      },
    ],
  },
  {
    element: <RequirePermission anyOf={["isLeadership"]} />,
    children: [
      {
        path: "/templates",
        element: <TemplateEditor />,
      },
    ],
  },
]

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
export function useRouters() {
  const session = useSession()

  return sentryCreateBrowserRouter([
    // return createBrowserRouter([
    {
      loader: async () => {
        if (session && session.user) {
          throw redirect("/")
        }
        return null
      },
      children: loggedOutPages,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      loader: async () => {
        if (!session || !session.user) {
          throw redirect("/login")
        }
        return null
      },
      children: loggedInPages,
    },
  ])
}
